import { MouseEvent, useState, useEffect, Dispatch, SetStateAction } from "react"
import API from '../../api';
import { CircularProgress, Tooltip } from "@mui/material";
import { Limit, SideInfo } from "../../types/all.types";
import EditInfo from "../Data/EditInfo";
import NumberLine from "../Data/NumberLine";
import { getThresholdValue } from "../../data/functions";
import ThresholdSlider from "../Data/ThresholdSlider";
import { useLocation } from "react-router-dom";
import NoImg from '../../assets/noImg.svg';
import ScreenshotButton from "../Buttons/ScreenshotButton";
import CopySmilesButton from "../Buttons/CopySmilesButton";
import useTruncateText from "../../hooks/useTruncateText";
import useRenderMolecule from "../../hooks/useRenderMolecule";
import CreateReadacrossButton from "../Buttons/CreateReadacrossButton";
import { useSelector } from "react-redux";
import { selectMetadata } from "../../store/selectors/auth.selector";
import { PopupType } from "../../hooks/usePopup";

export default function ChemicalPopup({ togglePopup, id, info, records, limits, isConfPred, addSingleItem, setPopupType }: { 
    togglePopup: (e:MouseEvent<HTMLButtonElement>) => void, 
    id: string,
    info?: SideInfo[],
    records?: any,
    limits?: Limit[],
    isConfPred?: boolean,
    addSingleItem?: (id: string) => void,
    setPopupType?: Dispatch<SetStateAction<PopupType>>,

}) {
    const [ identifier, setIdentifier ] = useState('');
    const [ fields, setFields ] = useState<any | null>(null);
    const [ threshold, setThreshold ] = useState(0.5);
    const location = useLocation();
    const { isTextTruncated, textRef } = useTruncateText();
    const { isNotFound, src, isLoading } = useRenderMolecule({ id, size: 200 });
    const metadata = useSelector(selectMetadata);


    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
            if (authKey) {


                const fetchMoleculeInfo = async (id: string) => {
                    const res = await API.get(`molecule/${id}`, { headers: { 'X-Auth': authKey } });
                    const molData = await res.data;
                    setIdentifier(molData.smiles);
                }

                fetchMoleculeInfo(id);
            }
        
    }, [id]);

    useEffect(() => {
        if (records && records.length > 0) {
            const obj = records.find((r:any) => r.id === id);
            if (obj) {
                let fieldObj: any = {};
                Object.keys(obj).forEach(key => {
                    if (['ad-score', 'ada', 'DL', 'bnb', 'knn', 'lreg', 'rf', 'svc', 'xgb'].includes(key)) {
                        fieldObj[key] = obj[key];
                    }
                })
                
                setFields(fieldObj);

            }
        } else {
            setFields(null)
        }
    }, [records, id]);

    

    return (
        <>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded max-w-[1200px] w-full'>
                    <div className='popup-max-height overflow-hidden'>
                        <div className='grid grid-cols-2 grid-rows-1 gap-6'>
                                
                            <div className='rounded flex flex-col items-end gap-6 p-6 bg-white'>
                                <div className="flex gap-3 justify-end items-center">
                                    {/* {metadata && metadata.config.readacross === 'true' && addSingleItem && setPopupType && <CreateReadacrossButton id={id} width="16px" setPopupType={setPopupType} addSingleItem={addSingleItem}/>} */}
                                    <ScreenshotButton width="22px" identifier={identifier}/>  
                                </div>
                                {isLoading ? (
                                    <div className="w-full aspect-square flex justify-center items-center text-[#D9D9D9]"><CircularProgress size={100} color='inherit'/></div>
                                ) : src ? (
                                    <div id='structure-img' className="w-full max-h-[600px]"><img src={src} alt='chemical-structure-svg' className="object-cover w-full"/></div>
                                ) : isNotFound ? (
                                    <div className="w-full max-h-[600px]"><img src={NoImg} alt='no-img' className="object-cover w-full"/></div>
                                ) : (
                                    <div className="w-full aspect-square flex justify-center items-center text-[#D9D9D9]"><CircularProgress size={100} color='inherit'/></div>
                                )}
                            </div>

                            <div className="flex flex-col justify-between items-stretch gap-6 py-6 pr-6">
                                <div className="flex flex-col justify-start items-stretch gap-6">
                                <div className="w-full flex justify-between items-center">
                                    <Tooltip title={isTextTruncated ? identifier : ''} disableHoverListener={!isTextTruncated}>
                                        <p ref={textRef} className="font-semibold text-[18px] text-primary max-w-[500px] truncate">{identifier}</p>
                                    </Tooltip>
                                    <CopySmilesButton identifier={identifier} iconOnly={true} customWidth="20px"/>
                                </div>
                                <div className="max-h-[500px] overflow-y-auto">
                                    <div className="flex flex-col gap-6 justify-start items-stretch">
                                        {fields !== null && isConfPred && (
                                            <div className="">
                                                <ThresholdSlider threshold={threshold} setThreshold={setThreshold}/>
                                            </div>
                                        )}

                                        <div className='flex flex-col gap-1 justify-center items-stretch'>
                                            {fields !== null && Object.keys(fields).map(key => {
                                                const limitsObj = limits && limits.find(limit => limit.value === key);
                                                if (limitsObj && !Array.isArray(fields[key])) {
                                                    return (
                                                        <NumberLine label={key} max={limitsObj.max} min={limitsObj.min} value={Number((Math.round(Number(fields[key as keyof object]) * 100) / 100 as number).toFixed(2))} />
                                                    )
                                                } else {
                                                    const { color, label } = getThresholdValue(fields[key], threshold);

                                                    return (
                                                        <div className="w-full p-1 flex flex-col gap-1 justify-center items-start">
                                                            <p className="font-semibold text-secondary uppercase">{key}</p>
                                                            <div style={{backgroundColor: color}} className="py-1 px-3 w-full text-center text-[12px] rounded-full font-medium">
                                                                {label}
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                        
                                        {info && !location.pathname.includes('readacross') ? (
                                            <EditInfo info={info} isEdit={false} />
                                        ) : info && location.pathname.includes('readacross') && (
                                            <>
                                            {info.map(n => {
                                                const rounded = Math.round(Number(n.value));
                                                return (
                                                    <div className="w-full p-1 flex flex-col gap-1 justify-center items-start">
                                                        <p className="font-semibold text-secondary uppercase">{n.label}</p>
                                                        <div className={`py-1 px-3 w-full text-center text-[12px] rounded-full font-medium ${rounded === 0 ? 'bg-[#7BC960]' : 'bg-[#FF8484]'}`}>
                                                            {n.value}
                                                        </div>

                                                    </div>
                                                )
                                            })}
                                            </>
                                        )}
                                        
                                        
                                    </div>
                                </div>
                                </div>
                                <div className="w-full flex justify-end items-center">
                                    <div className="flex gap-2">
                                        <button onClick={togglePopup} className='flex items-center justify-center gap-2 py-2 px-4 rounded text-white text-[14px] text-medium border border-tertiary bg-tertiary hover:bg-secondary hover:border-secondary transition-all font-medium disabled:border-quaternary/[0.3] disabled:bg-quaternary/[0.3]'>
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}