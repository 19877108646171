import { Config } from "plotly.js";
import Plot from 'react-plotly.js';
import { VisualizationGroupType } from "../../../../types/predictions.types";
import { useEffect, useState, MouseEvent, Dispatch, SetStateAction } from "react";
import ChevronRight from '../../../../assets/chevron-right-black.svg';
import ChevronLeft from '../../../../assets/chevron-left-black.svg';
import SelectedMolecule from "./SelectedMolecule";
import TrashIcon from '../../../../assets/trash.svg';
import GreenTrashIcon from '../../../../assets/green-trashcan.svg';
import ExportIcon from '../../../../assets/download.svg';
import PurpleExportIcon from '../../../../assets/purpleDownload.svg';
import { capitalize, download } from "../../../../data/functions";
import { PopupType } from "../../../../hooks/usePopup";
import API from '../../../../api';
import { CircularProgress } from "@mui/material";
import { Tooltip } from "@mui/material";

const trainingSetColors = [
    '#ff7f0e',  // safety orange
    '#2ca02c',  // cooked asparagus green
    '#d62728',  // brick red
    '#9467bd',  // muted purple
    '#8c564b',  // chestnut brown
    '#e377c2',  // raspberry yogurt pink
    '#7f7f7f',  // middle gray
    '#bcbd22',  // curry yellow-green
    '#17becf'   // blue-teal
]

export default function VisualizationBox({ group, setPopupType, togglePopup, setSelectedPlots, setPopupMessage }: {
    group: VisualizationGroupType,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
    togglePopup: (e:any) => void,
    setSelectedPlots: Dispatch<SetStateAction<string[]>>,
    setPopupMessage: (message: string, isSuccess: boolean) => void,
}) {
    const [ index, setIndex ] = useState(0);
    const [ plotData, setPlotData ] = useState<any[]>([]);

    const [ selectedMolecule, setSelectedMolecule ] = useState<{molecule_id?: string, molecule_smile?: string, set_type: string, name: string, color: string} | null>(null);
    const [ selectedPoint, setSelectedPoint ] = useState<{traceIndex: number | null, pointIndex: number | null}>({traceIndex: null, pointIndex: null});
    const [ traceVisibility, setTraceVisibility ] = useState<boolean[]>([]);

    const [ isDownloadingPlot, setIsDownloadingPlot ] = useState(false);
    const [ isDownloadingAll, setIsDownloadingAll ] = useState(false);

    // onClick={(e:any) => {toggleDelete(e); setSelectedPlot(group.visualization_methods[i]._id.$oid)}}


    const toggleIndex = (e:MouseEvent<HTMLButtonElement>, type: 'prev' | 'next') => {
        e.preventDefault();

        if (type === 'next') {
            if (index !== plotData.length - 1) {
                setIndex(index + 1);
            }
            setSelectedMolecule(null);
        } else if (type === 'prev') {
            if (index !== 0) {
                setIndex(index - 1);
            }
            setSelectedMolecule(null);
            setSelectedPoint({traceIndex: null, pointIndex: null});

            const method = Object.keys(group.visualization_methods).map(m => m)[0];
            setTraceVisibility(Object.keys(group.visualization_methods[method as keyof object].visualization_result).map(m => true));
        }

    };

    const getMolecule = (e:any) => {
        const point = e.points[0];
        setSelectedMolecule(point.customdata);

        setSelectedPoint({ traceIndex: point.curveNumber, pointIndex: point.pointIndex })
    };

    const toggleTraceVisibility = (index: number) => {
        const newVisibility = [...traceVisibility];
        newVisibility[index] = !newVisibility[index]; // Toggle the visibility
        setTraceVisibility(newVisibility);
    }


    const config: Partial<Config> = {
        scrollZoom: true,
        displaylogo: false,
        displayModeBar: true,
        responsive: true,
        modeBarButtonsToRemove: ['toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian'],
        toImageButtonOptions: {
            format: 'svg',
            scale: 1,
        }
    };

    const layout_reg: () => Partial<Plotly.Layout> = function() {
    
        return {
          responsive: true,
          autosize: true,
          showlegend:false,
          margin: {
            l: 30,
            r: 10,
            b: 30,
            t: 60,
            pad: 0
          },
          modebar: {
            color: '#555555',
            activecolor: '#315FB5',
          },
        }
    };

    useEffect(() => {
        if (group) {
            const method = Object.keys(group.visualization_methods).map(m => m)[0];
            setTraceVisibility(Object.keys(group.visualization_methods[method as keyof object].visualization_result).map(m => true));
        }
    }, [group]);
    
    useEffect(() => {
        let data: any[] = [];
        group.visualization_methods.forEach(method => {
            let resultTrace = {};
            let modelCount = 0;
            let modelTraces: any[] = [];
            Object.keys(method.visualization_result).forEach((vis) => {
                let obj = method.visualization_result[vis];
                let moleculeInfo: any[] = [];

                obj.x.forEach((x, i) => {
                    let molObj = {};
                    if (obj.molecule_ids) {
                        (molObj['molecule_id' as keyof object] as string) = obj.molecule_ids[i];
                    }
                    if (obj.molecule_smiles) {
                        (molObj['molecule_smile' as keyof object] as string) = obj.molecule_smiles[i];
                    }
                    (molObj['set_type' as keyof object] as string) = vis.includes('Test') ? 'test' : 'train';
                    (molObj['name' as keyof object] as string) = vis;
                    (molObj['color' as keyof object] as string) = trainingSetColors[modelCount];
                    moleculeInfo.push(molObj)
                });

                if (vis.includes('Test')) {
                    resultTrace = {
                        x: obj.x,
                        y: obj.y,
                        mode: 'markers',
                        type: 'scatter',
                        name: 'Test',
                        marker: { color: '#1f77b4' },
                        selectedpoints: selectedPoint.traceIndex === 0 ? [selectedPoint.pointIndex] : null,
                        selected: {
                            marker: { size: 12, color: '#FF00FF' },  // Style for selected points
                        },
                        visible: traceVisibility[0] ? true : 'legendonly',
                        customdata: moleculeInfo,
                    }
                } else {
                    let modelTrace = {};

                    modelTrace = {
                        x: obj.x,
                        y: obj.y,
                        mode: 'markers',
                        type: 'scatter',
                        name: 'Train',
                        marker: { color: trainingSetColors[modelCount] },
                        selectedpoints: selectedPoint.traceIndex === modelCount+1 ? [selectedPoint.pointIndex] : null,
                        selected: {
                            marker: { size: 12, color: '#FF00FF' },  // Style for selected points
                        },
                        visible: traceVisibility[modelCount+1] ? true : 'legendonly',
                        customdata: moleculeInfo,
                    }

                    modelTraces.push(modelTrace);

                    modelCount += 1;
                }
            });

            data.push({
                plotInfo: method.visualization_method,
                plotData: ([resultTrace, modelTraces]).flat(),
            })

        });

        setPlotData(data);
    }, [group, selectedPoint, traceVisibility]);

    const exportVisData = async (e: MouseEvent<HTMLButtonElement>, plots: {id: string, name: string}[]) => {
        e.preventDefault();

        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            const promises = plots.map(async (plot) => {
                return API.get(`resultset_visualization/${plot.id}/download`, { headers: { 'X-Auth': authKey }, responseType: 'blob' }).then(res => res.data).then(data => download(`${group.fingerprint.name}-${plot.name}.xlsx`, data)).catch(err => console.log(err))
            });

            if (plots.length > 1) {
                setIsDownloadingAll(true)
            } else {
                setIsDownloadingPlot(true);
            }

            try {
                await Promise.all(promises);
                setPopupMessage('The data visualizations you selected were successfully exported.', true);
            } catch (err:any) {
                console.log(err);
                setPopupMessage('We encountered an issue downloading the plot data you selected. Please try again.', false);
            }

            setIsDownloadingAll(false);
            setIsDownloadingPlot(false);
        }
    }


    return (
        <div className={`w-full rounded bg-white drop-shadow-default flex flex-col gap-6 h-full p-8`}>
            <div className={`w-full flex justify-between items-center`}>
                <div className="flex flex-col gap-[2px] justify-start items-start">
                    <p className='text-[1.5rem] font-semibold text-nowrap'>{group.fingerprint.name}</p>
                    {group.fingerprint.params.nBits && <p className="font-semibold text-quaternary">Bits: {group.fingerprint.params.nBits} / Radius: {group.fingerprint.params.radius} / Use Chirality: {group.fingerprint.params.useChirality ? 'True' : 'False'}</p>}
                </div>
                {group.visualization_methods.length > 1 && (
                    <div className="flex justify-end items-stretch gap-4">
                        <button onClick={(e:any) => exportVisData(e, group.visualization_methods.map(method => ({id: method.unique_id, name: method.visualization_method.name})))} className="flex justify-center items-center gap-2 border border-[#362484] py-2 px-4 rounded text-[#362484] hover:text-white hover:bg-[#362484] transition-all lighten">
                            {isDownloadingAll ? <CircularProgress color="inherit" size={12}/> : <img src={PurpleExportIcon} className="w-[18px]"  alt='export-all-icon'/>}
                            <p className="font-medium text-[14px]">Export All Data</p>
                        </button>
                        <button onClick={(e:any) => {togglePopup(e); setPopupType('delete-vis'); setSelectedPlots(group.visualization_methods.map(method => method.unique_id))}} className="flex justify-center items-center gap-2 border border-secondary py-2 px-4 rounded text-secondary hover:text-white hover:bg-secondary transition-all lighten">
                            <img src={GreenTrashIcon} className="w-[18px]"  alt='delete-all-icon'/>
                            <p className="font-medium text-[14px]">Delete All Plots</p>
                        </button>
                        <div className='flex justify-center items-center rounded bg-darker-background shadow-inner'>
                            <button onClick={(e:any) => toggleIndex(e, 'prev')} className={`${index === 0 ? 'border-quaternary' : 'border-secondary'} border-t border-b border-l darken-icons flex justify-center items-center px-3 py-1 rounded-tl rounded-bl h-full relative toggle-btn`}>
                                {index > 0 && <div className='absolute top-0 left-0 right-0 bottom-0' />}
                                <img src={ChevronLeft} alt='left-arrow' className='w-[14px]' />
                            </button>
                            <button onClick={(e:any) => toggleIndex(e, 'next')} className={`${index > 0 ? 'border-quaternary' : 'border-secondary'} border darken-icons flex justify-center items-center px-3 py-1 rounded-tr rounded-br h-full relative toggle-btn`}>
                                {index < plotData.length-1 && <div className='absolute top-0 left-0 right-0 bottom-0' />}
                                <img src={ChevronRight} alt='right-arrow' className='w-[14px]' />
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {plotData.length > 0 && plotData.map((data,i) => (
            <>
            {i === index && (
            <div className="w-full flex justify-between items-start gap-6">
                <div className="grow">
                    <Plot onClick={getMolecule} layout={layout_reg()} data={data.plotData} config={config} className='w-full aspect-square'/>
                </div>
                <div className="w-[400px] flex flex-col justify-start items-stretch gap-4">
                    <div className="w-full rounded border-2 border-quaternary overflow-hidden">
                        <div className="w-full flex justify-between items-center gap-4 bg-[#e8e8e8] py-4 px-5">
                            <p className="font-semibold text-primary">{data.plotInfo.name}</p>
                            <div className="flex justify-end items-center gap-4">
                                <Tooltip title='Export Plot' placement="bottom">
                                    <button onClick={(e:any) => exportVisData(e, [{id: group.visualization_methods[i].unique_id, name: group.visualization_methods[i].visualization_method.name}])} className='highlight flex justfiy-center items-center gap-2'>
                                        {isDownloadingPlot ? <CircularProgress color="inherit" size={12}/> : <img src={ExportIcon} className="w-[18px]" alt='export-icon' />}
                                    </button>
                                </Tooltip>
                                <Tooltip title='Delete Plot' placement="bottom">
                                    <button onClick={(e:any) => {togglePopup(e); setPopupType('delete-vis'); setSelectedPlots([group.visualization_methods[i].unique_id])}} className='highlight flex justfiy-center items-center gap-2'>
                                        <img src={TrashIcon} className='w-[18px]' alt='remove-icon' />
                                    </button>
                                </Tooltip>
                                
                            </div>
                        </div>
                        <div className="w-full flex flex-col gap-4 py-4 px-5">
                            <div className='w-full flex flex-col gap-1 justify-center items-start'>
                                <label className={`text-[12px] font-semibold text-primary`}>Legend</label>
                                <div className="w-full flex flex-col bg-[#e8e8e8] rounded overflow-hidden">
                                    <button onClick={(e:any) => toggleTraceVisibility(0)} className={`w-full flex justify-start items-center gap-4 p-4 ${traceVisibility[0] ? 'opacity-100' : 'opacity-30'}`}>
                                        <div className={`w-[20px] aspect-square bg-[#1f77b4] `}></div>
                                        <p className={`font-medium truncate`}>Test - {data.plotData[0].customdata[0].name.replace('(Test)', '')}</p>
                                    </button>
                                    {data.plotData.filter((d:any) => d.customdata[0].set_type === 'train').length > 1 ? data.plotData.filter((d:any) => d.customdata[0].set_type === 'train').map((train:any, i: number) => (
                                        <button onClick={(e:any) => toggleTraceVisibility(i+1)} className={`w-full flex justify-start items-center gap-4 p-4 ${traceVisibility[i+1] ? 'opacity-100' : 'opacity-30'}`}>
                                            <div style={{backgroundColor: train.customdata[0].color}} className={`w-[20px] h-[20px] aspect-square`}></div>
                                            <p className={`font-medium break-words truncate`}>Train - {train.customdata[0].name.replace('(Train)', '')}</p>
                                        </button>
                                    )) : data.plotData.filter((d:any) => d.customdata[0].set_type === 'train').length !== 0 ? (
                                        <button onClick={(e:any) => toggleTraceVisibility(1)} className={`w-full flex justify-start items-center gap-4 p-4 ${traceVisibility[1] ? 'opacity-100' : 'opacity-30'}`}>
                                            <div className={`w-[20px] aspect-square bg-[#ff7f0e]`}></div>
                                            <p className={`font-medium truncate`}>Train - {data.plotData[1].customdata[0].name.replace('(Train)', '')}</p>
                                        </button>
                                    ) : (
                                        <></>
                                    )}
                                    
                                </div>         
                            </div>
                            {Object.keys(data.plotInfo.params).map(param => (
                                <div className='w-full flex flex-col gap-1 justify-center items-start'>
                                    <label className={`text-[12px] font-semibold text-primary`}>{capitalize(param)}</label>
                                    <div className="w-full rounded border border-primary focus:outline-tertiary p-3">
                                        {data.plotInfo.params[param] === true ? 'true' : data.plotInfo.params[param] === false ? 'false' : data.plotInfo.params[param]}
                                    </div>          
                                </div>
                            ))}
                        </div>
                    </div>
                    <SelectedMolecule selectedMolecule={selectedMolecule}/>
                </div>
            </div>
            )}
            </>
            ))}
            
        </div>
    )
}