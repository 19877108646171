import { MouseEvent, useState, useEffect } from "react"
import API from '../../../api';
import { CircularProgress } from "@mui/material";
import Select from 'react-select';
import Field from "../../../components/Form/Field";
import { ReadAcrossForm, ReadAcrossType } from "../../../types/readacross.types";
import { ChangeEvent } from "react";
import { selectDatasets } from "../../../store/selectors/datasets.selector";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dataset } from "../../../types/datasets.types";
import { addReadacross } from "../../../store/slices/readacross.slice";
import { cleanFileName } from "../../../data/functions";
import ErrorMessage from "../../../components/Alerts/ErrorMessage";
import ErrorIcon from '../../../assets/alert-circle.svg';
import FormButtons from "../../../components/Buttons/FormButtons";

const defaultForm: ReadAcrossForm = {
    dataset_for_ra: [],
    desc: '',
    filter: '',
    mol_id: '',
    num_neighbors: 10,
    result_name: '',
    smiles: 'False',
    toxref: true,
}

const neighborsOptions = [
    {value: 'ECFP', label: 'ECFP'},
    {value: 'MACCS', label: 'MACCS'}
];

const modelTypeOptions = [
    {value: 'Classification', label: 'Classification'},
    {value: 'Regression', label: 'Regression'}
];

const datasetOptions = [
    {value: 'ToxRef', label: 'ToxRef'},
    {value: 'AssayCentral', label: 'AssayCentral'}
]

export default function ReadacrossPopup({ togglePopup, items, deselectItems }: { 
    togglePopup: (e:MouseEvent<HTMLButtonElement>) => void, 
    items: string[],
    deselectItems: () => void,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [svg ,setSvg] = useState<TrustedHTML>();
    const [ form, setForm ] = useState<ReadAcrossForm>(defaultForm);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(true);
    const datasets = useSelector(selectDatasets);
    const [datasetNameOptions, setDatasetNameOptions] = useState<{value: string, label: string}[]>([]);
    const [ selectedDatasets, setSelectedDatasets ] = useState<{value: string, label: string}[]>([]);
    const [ errorMessage, setErrorMessage ] = useState('');


    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
            if (authKey && items[0]) {
                const fetchImage = async (id: string) => {
                    const res = await API.get(`render/${id}?size=200`,  { headers: { 'X-Auth': authKey } })
                    const data = await res.data;
                    setSvg(data);
                }

                fetchImage(items[0]);
               
            }
        
    }, [items]);

 

    useEffect(() => {
        if (svg) {
            const div = document.getElementById(items[0] + 'popup');
            console.log(div)
            if (div) {
                div.firstElementChild?.setAttribute('width', '100%');
                div.firstElementChild?.setAttribute('height', '100%');
            }
        }
    }, [svg, items]);

    const handleSelect = (e:any, name: string) => {
        setForm({
            ...form,
            [name]: e.value,
        })
    };

    const handleDataset = (e:any) => {
        if (e.value === 'ToxRef') {
            setForm({
                ...form,
                toxref: true,
            });
            setSelectedDatasets([]);
        } else {
            setForm({
                ...form,
                toxref: false,
            })
        }
    };

    const handleMulti = (e:any) => {
        setSelectedDatasets(e);
    }

    const handleInput = (e:ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }

    const cancel = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setForm(defaultForm);
        togglePopup(e);
        deselectItems();
    }

    useEffect(() => {
        if (!form.toxref && form.filter) {
            let classificationSets: Dataset[] = [];
            let regressionSets: Dataset[] = [];
    
            datasets.forEach(dataset => {
                const field = dataset.fields_mapping.find(f => ['continuous-value', 'single-class-label'].includes(f.type));
                if (field) {
                    if (field.type === 'continuous-value') {
                        regressionSets.push(dataset);
                    } else {
                        classificationSets.push(dataset)
                    }
                }
            })

            if (form.filter === 'Classification') {
                setDatasetNameOptions(classificationSets.map(set => ({value: set._id.$oid, label: set.name})))
            } else {
                setDatasetNameOptions(regressionSets.map(set => ({value: set._id.$oid, label: set.name})))
            }


        } else {
            setDatasetNameOptions([]);
        }
        
    }, [form, datasets]);

    useEffect(() => {
        if (!form.desc || !form.filter || !form.num_neighbors || !form.result_name || form.num_neighbors < 1 || form.num_neighbors > 15) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [form] );

    const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);

        const body: ReadAcrossForm = {
            ...form,
            dataset_for_ra: selectedDatasets.map(opt => opt.value),
            mol_id: items[0],
            result_name: cleanFileName(form.result_name),
        }



        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {

                const response = await API.post('readacross', body, { headers: { 'X-Auth': authKey } });
                const data: ReadAcrossType = await response.data;
                dispatch(addReadacross(data));
                togglePopup(e);
                const id = data._id.$oid;
                navigate(`/readacross/${id}`);
                setErrorMessage('');

            } catch(err:any) {
                setErrorMessage(err.response.data.detail);
            }
        }

        setIsDisabled(false);
        setIsLoading(false);
        
    }


    

    return (
        <>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded max-w-[1200px] w-full'>
                    <div className='popup-max-height overflow-y-auto'>
                        <div className='flex gap-6 justify-center items-stretch'>
                                
                            <div className='rounded flex flex-col gap-6 p-6 bg-white w-1/2'>
                                <div className="flex gap-3 justify-end items-center"></div>
                                {svg ? (
                                    <div className="w-full" id={items[0] + 'popup'} dangerouslySetInnerHTML={svg && {__html: svg}}></div>
                                ) : (
                                    <div className="w-full aspect-square flex justify-center items-center text-[#D9D9D9]"><CircularProgress size={100} color='inherit'/></div>
                                )}
                            </div>

                            <div className="w-1/2 flex flex-col justify-between items-stretch py-6 pr-6">
                                <div className="field-popup-max-height h-full overflow-y-auto">
                                    <div className="flex flex-col gap-6 justify-start items-stretch">
                                        <p className="font-semibold text-[18px] text-primary">Create New Read-Across</p>
                                        <Field label='Result Name' isRequired={true}>
                                            <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                                <input required name='result_name' onChange={handleInput} value={form.result_name} className='rounded-tl rounded-bl text-left text-[14px] p-2 grow' placeholder='Result Name'/>
                                            </div> 
                                        </Field>

                                        <Field label='Neighbors' isRequired={true}>
                                            <Select required onChange={(e:any) => handleSelect(e, 'desc')} value={form.desc ? {value: form.desc, label: form.desc} : null} options={neighborsOptions} className='text-left text-[14px] rounded w-full' placeholder='Neighbors'/>
                                        </Field>

                                        <Field label='Model Type' isRequired={true}>
                                            <Select required onChange={(e:any) => handleSelect(e, 'filter')} value={form.filter ? {value: form.filter, label: form.filter} : null} options={modelTypeOptions} className='text-left text-[14px] rounded w-full' placeholder='Model Type'/>
                                        </Field>

                                        <Field label='Analog Number' isRequired={true}>
                                            <input required type='number' min={1} max={15} onChange={handleInput} value={form.num_neighbors} name='num_neighbors' className="rounded text-left p-2 w-full border border-primary invalid:border-[#FF8484]" placeholder="Analog Number"/>
                                            {(form.num_neighbors < 1 || form.num_neighbors > 15) && (
                                                <div className="flex justify-start items-center gap-1 text-[#FF8484]">
                                                    <img src={ErrorIcon} alt='error-icon' className="w-[12px]"/>
                                                    <p className="font-medium text-[12px]">This value must be between 1 and 15</p>
                                                </div>
                                            )}
                                        </Field>

                                        <Field label='Dataset Type' isRequired={true}>
                                            <Select required onChange={handleDataset} value={form.toxref ? datasetOptions[0] : datasetOptions[1]} options={datasetOptions} className='text-left text-[14px] rounded w-full' placeholder='Model Type'/>
                                        </Field>

                                        {form.filter && !form.toxref && (
                                            <div className="mb-6">
                                            <Field label='Dataset' isRequired={true}>
                                                <Select isMulti required value={selectedDatasets.length ? selectedDatasets : null} onChange={handleMulti} options={datasetNameOptions} className='text-left text-[14px] rounded w-full' placeholder='Selected datasets'/>
                                            </Field>
                                            </div>
                                        )}

                                        {errorMessage && (
                                            <ErrorMessage errorMessage={errorMessage} />
                                        )}
                                    </div>
                                </div>
                                
                                <FormButtons cancelFunction={cancel} continueFunction={handleSubmit} isDisabled={isDisabled} isLoading={isLoading}/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}