import { Tooltip } from "@mui/material"
import CopySmilesButton from "../../../../components/Buttons/CopySmilesButton"
import useTruncateText from "../../../../hooks/useTruncateText"
import { capitalize } from "../../../../data/functions";

export default function SelectedMoleculeInfo({ smiles, setType, name, color }: {
    smiles: string,
    setType: string,
    name: string,
    color: string,
}) {

    const { isTextTruncated, textRef } = useTruncateText();

    return (
        <>
            <div className="w-full flex justify-between items-center gap-4 bg-[#e8e8e8] py-4 px-5">
                <Tooltip title={isTextTruncated ? smiles : ''} disableHoverListener={!isTextTruncated}>
                    <p ref={textRef} className="font-semibold text-primary max-w-[300px] truncate">{smiles}</p>
                </Tooltip>
                <CopySmilesButton identifier={smiles} iconOnly={true}/>
            </div>
            <div className="w-full flex justify-start items-start gap-4 bg-darker-background py-4 px-5">
                <div style={{backgroundColor: setType === 'test' ? '#1f77b4' : color}} className="w-[20px] h-[20px] aspect-square"></div>
                <p className="font-medium break-words truncate">{capitalize(setType)} - {name.replace('(Train)', '')}</p>
            </div>
        </>
    )
}