import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";

import { defaultJobsFilter, defaultJobsFilterFields, jobHeaders } from "../../data/jobs";
import { selectJobs } from "../../store/selectors/jobs.selector";
import { deleteExistingJobs, getJobs } from "../../store/slices/jobs.slice";
import { filterItems, getDateFromObjectId, orderByDate, searchItems, sortByColumn } from "../../data/functions";

import { ParsedJobs, AssociatedItems, GroupData, Job } from "../../types/jobs.types";
import { FilterFieldsType, FilterType, Header } from "../../types/all.types";

import API from '../../api';
import { useNavigate } from "react-router-dom";

const useJobs = ({ setPopupMessage, searchInput, selectedHeader }: { 
    setPopupMessage?: (popupMessage: string, isSuccessMessage: boolean) => void,
    searchInput: string,
    selectedHeader?: string,
}) => {
    const dispatch = useDispatch();
    const jobs = useSelector(selectJobs);
    const navigate = useNavigate();

    const [ allJobs, setAllJobs ] = useState<ParsedJobs[]>([]);
    const [ jobGroups, setJobGroups ] = useState<string[]>([]);

    const [ associatedItems, setAssociatedItems ] = useState<AssociatedItems[]>([]);
    const [ groupData, setGroupData ] = useState<GroupData[]>([]);
    const [ modifiedJobs, setModifiedJobs ] = useState<GroupData[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ headers, setHeaders ] = useState<Header[]>(jobHeaders);

    const [ filterFields, setFilterFields ] = useState<FilterFieldsType>(defaultJobsFilterFields);
    const [ filter, setFilter ] = useState<FilterType>(defaultJobsFilter);


    useEffect(() => {
        setHeaders(jobHeaders);
    }, []);



    

    

    useEffect(() => {
        const fetchJobs = async () => {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {
                
                try {
    
                    const response = await API.get('jobs', { headers: { 'X-Auth': authKey } });
                    const data: Job[] = await response.data;
                    dispatch(getJobs(data));
                   
                } catch (err: any) {
    
                    console.log(err);
                    navigate('/500');
    
                }
                
            } else {
                navigate('/401');
            }
        }

        setIsLoading(true);
        fetchJobs();
        setIsLoading(false);

        const refreshJobs = setInterval(() => {
            fetchJobs();
        }, 5000);

        return () => clearInterval(refreshJobs);
        
    }, [dispatch, navigate]);

    const getJobName = (job: Job) => {
        if (job.job_type === 'visualize_resultset') {
            if (job.params.rs_name && job.params.fingerprint_method && job.params.visualization_method) {
                return `${job.params.rs_name}-${job.params.fingerprint_method}-${job.params.visualization_method}`
            } else {
                return ''
            }
        } else if (job.job_type === 'train') {
            if (job.params.method) {
                return job.params.method;
            } else {
                return ''
            }
        } else if (job.job_type === 'predict') {
            if (job.params.rs_name) {
                return job.params.rs_name;
            } else {
                return ''
            }
        } else if (job.job_type === 'ad_domain') {
            return job.params.ds_name;
        } else {
            return ''
        }
    };

    const getJobResult = (job: Job) => {
        if (job.job_type === 'visualize_resultset') {
            if (job.params.rs_name) {
                return `${job.params.rs_name}`
            } else {
                return ''
            }
        } else if (job.job_type === 'train') {
            if (job.params.model_name) {
                return job.params.model_name;
            } else {
                return ''
            }
        } else if (job.job_type === 'predict') {
            if (job.params.rs_name) {
                return job.params.rs_name;
            } else {
                return ''
            }
        } else if (job.job_type === 'ad_domain') {
            return job.params.ds_name;
        } else {
            return ''
        }
    }

    useEffect(() => {
        if (jobs && jobs.length > 0) {

            const vis = jobs.filter(job => job.job_type === 'visualize_resultset')
            console.log(vis)

            
            const ordered: ParsedJobs[] = jobs.map(job => {

                return ({
                    id: job._id.$oid,
                    name: getJobName(job),
                    model_id: job.params.all_ml_models_job_id !== undefined ? job.params.all_ml_models_job_id : undefined,
                    method_model: job.params.method && job.params.model_name ? job.params.model_name : '',
                    type: job.job_type,
                    dataset: job.params.ds_name,
                    result: getJobResult(job),
                    status: job.status,
                    execution_time: job.stats.execution_time,
                    container: job.container_name,
                    created_by: job.user ? job.user.username : '',
                    date_created: getDateFromObjectId(job._id.$oid).toLocaleString('en-US', {timeZone: 'EST'})
                })
            })
            setAllJobs(ordered);

            let groupIDs: string[] = []

            jobs.forEach(job => {
                let id = '';
                if (job.params.all_ml_models_job_id !== undefined) {
                    id = job.params.all_ml_models_job_id;
                } else {
                    id  = job._id.$oid;
                }

                if (!id) return;

                if (!groupIDs.length) {
                    groupIDs.push(id);
                } else if (!groupIDs.includes(id)) {
                    groupIDs.push(id);
                }
            })

            setJobGroups(groupIDs);
        }
    }, [jobs]);


    useEffect(() => {
        let arr: AssociatedItems[] = [];

        if (allJobs.length > 0 && jobGroups.length > 0) {
            jobGroups.forEach(group => {
                let items: ParsedJobs[] = [];
                allJobs.forEach(job => {
                    if (job.model_id !== undefined) {
                        if (job.model_id  === group) {
                            items.push(job);
                        }
                    } else if (job.id === group) {
                        items.push(job);
                    }
                });

                let name = '';

                const trainingJob = allJobs.find(j => j.model_id === group);
                const adJob = allJobs.find(j => j.id === group);

                if (trainingJob) {
                    name = trainingJob.method_model
                } else if (adJob) {
                    name = adJob.name
                }

                arr.push({
                    groupName: name,
                    groupID: group,
                    items,
                })
            })
        }
        
        setAssociatedItems(arr);
    }, [allJobs, jobGroups]);

    useEffect(() => {
        let arr: GroupData[] = [];

        if (associatedItems.length > 0) {
            associatedItems.forEach(item => {
                const { type, dataset, result, status, execution_time, container, date_created, created_by } = item.items[0];
                arr.push({ name: item.groupName, id: item.groupID, type, dataset, result, status, execution_time, container, date_created, created_by });
            })
        }


        const ordered = orderByDate(arr);

        setGroupData(ordered);
        modifyJobs(searchInput, selectedHeader ? selectedHeader : 'date_created', undefined, filter, ordered);
        // setModifiedJobs(ordered);
    }, [associatedItems]);



    useEffect(() => {
        if (groupData.length > 0) {
            const type = groupData.map(file => file.type);
            const dataset = groupData.map(file => file.dataset);
            const result = groupData.map(file => file.result);
            const status = groupData.map(file => file.status);
            const execution_time = groupData.map(file => file.execution_time);
            const container = groupData.map(file => file.container);
            setFilterFields({
                type: Array.from(new Set(type)).filter(a => a).map(p => p),
                dataset: Array.from(new Set(dataset)).filter(a => a).map(p => p),
                result: Array.from(new Set(result)).filter(a => a).map(p => p),
                status: Array.from(new Set(status)).filter(a => a).map(p => p),
                execution_time: Array.from(new Set(execution_time)).filter(a => a),
                container: Array.from(new Set(container)).filter(a => a),
            })
        }
        
    }, [groupData]);

    const modifyJobs = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>, filter?: FilterType, ordered?: GroupData[]) => {
        const searchArr = searchItems(searchInput, ordered ? ordered : groupData);
        const sortedArr = sortByColumn(selectedHeader, headers, false, searchArr, updateHeaders);
        const filteredArr = filterItems(filter!, sortedArr);
        setModifiedJobs(filteredArr);
    }

    const deleteJobs = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`jobs/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingJobs(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage(`The ${ids.length > 1 ? 'jobs you selected were' : 'job you selected was'} deleted successfully`, true);
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error deleting ${ids.length > 1 ? 'these jobs' : 'this job'}`, false)
            }
        }
    }

    return {
        groupData,
        filterFields,
        modifiedJobs,
        modifyJobs,
        associatedItems,
        isLoading,
        deleteJobs,
        jobs,
        setFilter,
        filter,
        setHeaders,
        headers,
    }

}

export default useJobs;