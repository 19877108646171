import { useParams } from "react-router-dom"
import { useEffect, useState, MouseEvent } from "react";
import usePagination from "../../../hooks/usePagination";
import usePopup from "../../../hooks/usePopup";
import useEnableSelection from "../../../hooks/useEnableSelection";
import useLayout from "../../../hooks/useLayout";
import AdditionalInfoSideMenu from "../../../components/Popups/AdditionalInfoSideMenu";
import DeletePopup from "../../../components/Popups/DeletePopup";
import ChemicalPopup from "../../../components/Popups/ChemicalPopup";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { SideInfo } from "../../../types/all.types";

import TableControls from "../../../components/Table/TableControls";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import ToggleView from "../../../components/Page/ToggleView";
import SinglePageHeader from "../../../components/Page/SinglePageHeader";
import Pagination from "../../../components/Page/Pagination";
import Button from "../../../components/Buttons/Button";
import DataRepresentationBox from "../../../components/Data/DataRepresentationBox";

import Info from '../../../assets/info-circle.svg';

import Grid from "../../../components/Grid/Grid";
import Zoomist from "../../../components/Data/Zoomist";
import EmptyTable from "../../../components/Table/EmptyTable";
import useSingleReadacross from "../../../hooks/pageHooks/useSingleReadacross";
import useMessage from "../../../hooks/useMessage";
import SnackBar from "../../../components/Alerts/SnackBar";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import GridItemBase from "../../../components/Grid/GridItemBase";
import ImageCell from "../../../components/Data/ImageCell";
import OptionsDropdownBase from "../../../components/Dropdowns/OptionsDropdownBase";
import IndividualReadAcrossOptions from "../components/IndividualReadAcrossOptions";
import Overlay from "../../../components/Popups/Overlay";
import useModifyTableItems from "../../../hooks/useModifyTableItems";
import API from '../../../api';

export default function SingleReadAcross() {
    const { id } = useParams();
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const [ image, setImage ] = useState<any | null>(null);
    const [ popupInfo, setPopupInfo ] = useState<SideInfo[]>([]);
    const [ moleculeID, setMoleculeID ] = useState('');

    const {
        open,
        setOpen,
        message,
        isSuccess,
        setPopupMessage,
    } = useMessage();
    
    const {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        headers,
        setHeaders,
        routes,
        singleReadacross,
        info,
        limits,
        deleteReadacross,
        downloadReadacross,
        updateReadacross,
        isLoading,
    } = useSingleReadacross({id, setPopupMessage});


    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedRecords, count: 50});

    const {
        selectHeaders,
        setSortedColumn,
    } = useModifyTableItems({ modifyItems: modifyRecords, setHeaders, searchInput: '', selectedHeader, setSelectedHeader })

    const { 
        isSideMenuOpen, 
        toggleSideMenu, 
        isPopupOpen,
        togglePopup,
        setPopupType,
        popupType,
        
    } = usePopup();

    const { 
        toggleView, 
        isGrid 
    } = useLayout();

    const { isChecked, toggleChecked } = useEnableSelection({allItems: undefined});
    


    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey && singleReadacross) {
            const fetchImage = async () => {

                try {
                    const response = await API.get(`/readacross/${id}/image`, { 
                        headers: { 'X-Auth': authKey },
                        responseType: 'blob', 
                    });
                    // const res = await fetch(`${process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '/api/'}readacross/${id}/image`, { headers: { 'X-Auth': authKey } })
                    const data = await response.data;
                    const src = URL.createObjectURL(data);
                    setImage(src);
                } catch (err:any) {
                    console.log(err);
                    setImage(null)
                }
                
            }

            fetchImage();
        }
        
    }, [singleReadacross, id]);

    useEffect(() => {
        if (moleculeID) {
            const record = modifiedRecords.find(r => r.id === moleculeID);
            if (record) {
                let info: SideInfo[] = [];
                Object.keys(record).forEach(key => {
                    if (record[key] && key !== 'id' && !key.includes('smile')) {
                        info.push({
                            label: key,
                            value: record[key]
                        })
                    }
                })

                setPopupInfo(info)
            }

            
        }
    }, [moleculeID, modifiedRecords]);

    const downloadImage = (e:MouseEvent<HTMLButtonElement>) => {
        if (image) {
            const anchor = document.createElement('a');
            anchor.download = `${id}.png`;
            anchor.href = image;
            anchor.click();
            anchor.remove();
        }
    }

    

    return (
        <>
            

                <SinglePageHeader label={singleReadacross ? singleReadacross.name : ''} isLoading={isLoading} routes={routes}>
                    <OptionsDropdownBase isLoading={isLoading}>
                        <IndividualReadAcrossOptions downloadFunc={downloadReadacross} toggleDelete={togglePopup} setPopupType={setPopupType} id={id} handleClose={() => {}}/>
                    </OptionsDropdownBase>
                    
                    <Button isLoading={isLoading} togglePopup={toggleSideMenu}>
                        <img src={Info} alt='info-logo' className="w-[16px]"/>
                        <p className='font-medium text-nowrap'>Show Details</p>
                    </Button>
                </SinglePageHeader>

                <div className='mb-6'>
                    <DataRepresentationBox label='Nearest Neighbors' downloadFunc={downloadImage}>
                        <div className='w-full flex justify-center items-center gap-10 overflow-hidden'>
                            {!image ? (
                                <div className="w-full"><Skeleton /></div>
                            ) : (
                                <div className="w-full flex justify-center items-center">
                                    <Zoomist>
                                    <img src={image} className="derv object-fill max-w-[600px] max-h-[600px]" alt='derivatives'/>
                                    </Zoomist>
                                </div>
                            )}
                        </div>
                    </DataRepresentationBox>
                </div>

                <div className="w-full rounded bg-white drop-shadow-default">
                {/* Search Bar & Buttons Component */}
                <TableControls toggleDeletePopup={togglePopup} setPopupType={setPopupType}>
                    <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                    <ToggleView toggleView={toggleView} isGrid={isGrid} />
                </TableControls>
                
                {/* Table Component */}
                <div className=''>
                {isGrid ? (
                    <>
                        <Grid isLoading={isLoading} noResults={!paginatedItems.length ? true : false}>
                            {paginatedItems.length > 0 ? (
                                <>
                                {paginatedItems && paginatedItems.map(item => {
                                    let title = '';
                                    const h = headers.find(header => header.isRequired && header.label !== 'Structure');
                                    if (h) {
                                        title = item[h.value];
                                    }
                                    return (
                                        <GridItemBase
                                        title={title}
                                        isChecked={false}
                                        gridType="structure"
                                        item={item}
                                        >
                                            <button onClick={(e:any) => {togglePopup(e); setPopupType('chemical') ;setMoleculeID(item.id)}}>
                                                <ImageCell type="grid" id={item.id} />
                                            </button>
                                            <div className='max-h-[180px] overflow-y-auto'>
                                                <div className='flex flex-col gap-4 justify-center items-stretch'>
                                                {Object.keys(item).filter(key => key !== 'Structure' && key.toLocaleLowerCase() !== 'smiles' && key !== 'id').map(key => {
                                                    const rounded = Math.round(Number(item[key]));
                                                    return (
                                                        <div className="w-full p-1 flex flex-col gap-1 justify-center items-start">
                                                            <p className="font-semibold text-secondary uppercase break-all">{key}</p>
                                                            <div className={`py-1 px-3 w-full text-center text-[12px] rounded-full font-medium ${rounded === 0 ? 'bg-[#7BC960]' : 'bg-[#FF8484]'}`}>
                                                                {item[key]}
                                                            </div>

                                                        </div>
                                                    )    
                                                })}
                                                </div>
                                            </div>
                                        </GridItemBase>
                                )})}
                                </>
                            ) : (
                                <EmptyTable isGrid={true} subject="No results found" message="Try adjusting your search to find what you are looking for" searchInput='' />
                            )}
                        </Grid>
                        {paginatedItems.length > 0 && (
                            <Pagination count={50} total={orderedRecords.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                            firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                        )}
                    </>
                ) : (
                    <>
                    {isLoading || paginatedItems.length > 0 ? (
                        <>
                            <TableBase
                                isLoading={isLoading}
                                isPopupOpen={false}
                                headers={headers} 
                                selectedHeader={selectedHeader} 
                                setSortedColumn={setSortedColumn}
                                hasOptions={false}
                                isChecked={false}
                            >
                                {paginatedItems.length && paginatedItems.map(item => (
                                    <TableRowBase 
                                        rowType="color"
                                        action={{setMoleculeID, togglePopup, setPopupType}}
                                        isChecked={isChecked} 
                                        item={item} 
                                        headers={headers} 
                                        limits={limits}
                                    />
                                ))}
                            </TableBase>
                            {paginatedItems.length > 0 && (
                                <Pagination count={50} total={orderedRecords.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                                firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                            )}
                        </>
                    ) : (
                        <EmptyTable subject="No results found" message="Try adjusting your search to find what you are looking for" searchInput='' />
                    )}

                    
                    </>
                    )}
                </div>
                </div>

                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>

            
            {isSideMenuOpen && (
                <AdditionalInfoSideMenu id={id} updateFunc={updateReadacross} info={info} label='Readacross Details' togglePopup={toggleSideMenu} />
            )}

            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup toggleChecked={toggleChecked} isChecked={isChecked} togglePopup={togglePopup} deleteFunc={deleteReadacross} items={id ? [id] : []} label='this readacross' path='/readacross'/>
                    ) : (
                        <ChemicalPopup togglePopup={togglePopup} id={moleculeID} info={popupInfo}/>
                    )}
                </Overlay>
            )}
           
        </>
    )
}