import { useEffect, useState, MouseEvent, Dispatch, SetStateAction } from "react";
import API from '../../api';
import { ClassificationModel, Model, RegreesionModel } from "../../types/models.types";
import { convertDecimal, download, filterItems, getDatedFilename, orderByDate, searchItems, sortByColumn } from "../../data/functions";
import { deleteModelsByType, getModels, setClassificationModels, setRegressionModels, updateModelsByType } from "../../store/slices/models.slice";
import { useDispatch } from "react-redux";
import { FilterFieldsType, FilterType, Header } from "../../types/all.types";
import { classificationHeaders, defaultClassificationModelFilter, defaultClassificationModelFilterFields, defaultModel, defaultRegressionModelFilter, regressionHeaders } from "../../data/models";
import { useSelector } from "react-redux";
import { selectClassificationModels, selectModels, selectRegressionModels } from "../../store/selectors/models.selector";

const useModels = ({ isClassification, aggr, setPopupMessage }: {
    isClassification: boolean;
    aggr: 'max' | 'min';
    setPopupMessage?: (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {
    const [ models, setModels ] = useState<Model[]>([]);
    const [ parsedModels, setParsedModels ] = useState<ClassificationModel[] | RegreesionModel[]>([]);
    const [ modifiedModels, setModifiedModels ] = useState<ClassificationModel[] | RegreesionModel[]>([]);
    const [ allModelNames, setAllModelNames ] = useState<string[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const [ headers, setHeaders ] = useState<Header[]>(classificationHeaders);

    const [ filterFields, setFilterFields ] = useState<FilterFieldsType>(defaultClassificationModelFilterFields);
    const [ filter, setFilter ] = useState<FilterType>(defaultClassificationModelFilter);

    const dispatch = useDispatch();
    const classificationModels = useSelector(selectClassificationModels);
    const regressionModels = useSelector(selectRegressionModels);


    const fetchModelsByType = async (auth: string, aggregate: string, classification: boolean) => {

        setIsLoading(true); 

        try {

            const response = await API.get(`models?aggr=${aggregate}&model_type=${classification ? 'classification' : 'regression'}`, {
                headers: { 'X-Auth': auth } 
            });

            const data = await response.data;

            if (classification) {
                dispatch(setClassificationModels(data));
            } else {
                dispatch(setRegressionModels(data));
            }



        } catch (err:any) {
            console.log(err, 'error');
        }

        setIsLoading(false);
    };





    const fetchAllModels = async (auth: string) => {
        try {
            const response = await API.get('models?aggr=max', {
                headers: { 'X-Auth': auth }
            });

            const data = await response.data;

            dispatch(getModels(data));

        } catch (err:any) {
            console.log(err);
        }
    }
    

    


    useEffect(() => {
        if (isClassification) {
            setHeaders(classificationHeaders);
            setFilter(defaultClassificationModelFilter);
        } else {
            setHeaders(regressionHeaders);
            setFilter(defaultRegressionModelFilter);
        }
    }, [isClassification]);

    useEffect(() => {
        

        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            fetchModelsByType(authKey, aggr, isClassification);
        }
        // eslint-disable-next-line
    }, [isClassification, aggr]);

    useEffect(() => {
        if (isClassification && classificationModels && classificationModels.length > 0) {
            setModels(classificationModels);
        } else if (!isClassification && regressionModels && regressionModels.length > 0) {
            setModels(regressionModels.concat({...defaultModel, creation_time: 'Unknown'}));
        }
    }, [classificationModels, regressionModels, isClassification]);



    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            fetchAllModels(authKey);
        }
        // eslint-disable-next-line
    }, [])



    useEffect(() => {
        if (models.length > 0) {

            let arr: ClassificationModel[] | RegreesionModel[] = [];

            if (isClassification) {

                arr = models.map((m:Model) => ({
                    name: m.name,
                    n: m.n,
                    auc: convertDecimal(m.auc),
                    f1score: convertDecimal(m.f1score),
                    precision: convertDecimal(m.precision),
                    recall: convertDecimal(m.recall),
                    acc: convertDecimal(m.acc),
                    specificity: convertDecimal(m.specificity),
                    cohens_kappa: convertDecimal(m.cohens_kappa),
                    mcc: convertDecimal(m.mcc),
                    access: m.acl.access[0],
                    created_by: m.created_by,
                    date_created: m.creation_time === 'Unknown' ? new Date(0).toLocaleString() : new Date(m.creation_time).toLocaleString(),
                }));

            } else {

                arr = models.map((m:Model) => ({
                    name: m.name,
                    n: m.n,
                    mae: convertDecimal(m.mae),
                    rmse: convertDecimal(m.rmse),
                    r2: convertDecimal(m.r2),
                    mpd: convertDecimal(m.mpd),
                    mgd: convertDecimal(m.mgd),
                    access: m.acl.access[0],
                    created_by: m.created_by,
                    date_created: m.creation_time === 'Unknown' ? new Date(0).toLocaleString('en-US', {timeZone: 'EST'}) :  new Date(m.creation_time).toLocaleString('en-US', {timeZone: 'EST'}),
                }));

            };

            const ordered = orderByDate(arr);
    
            setParsedModels(ordered);
            setModifiedModels(ordered);
        }
    }, [models, isClassification]);


    

    useEffect(() => {
        if (modifiedModels.length > 0) {
            setAllModelNames(modifiedModels.map(model => model.name));
        }
    }, [modifiedModels]);

    const modifyModels = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>, filter?: FilterType) => {
        const searchArr = searchItems(searchInput, parsedModels);
        const sortedArr = sortByColumn(selectedHeader, headers, false, searchArr, updateHeaders);
        const filteredArr = filterItems(filter!, sortedArr);
        setModifiedModels(filteredArr);
    }



    useEffect(() => {
        if (modifiedModels.length > 0) {

            if (isClassification) {

                const n = (modifiedModels as ClassificationModel[]).map(file => file.n) as number[];
                const auc = (modifiedModels as ClassificationModel[]).map(file => file.auc) as number[];
                const f1score = (modifiedModels as ClassificationModel[]).map(file => file.f1score) as number[];
                const precision = (modifiedModels as ClassificationModel[]).map(file => file.precision) as number[];
                const recall = (modifiedModels as ClassificationModel[]).map(file => file.recall) as number[];
                const acc = (modifiedModels as ClassificationModel[]).map(file => file.acc) as number[];
                const specificity = (modifiedModels as ClassificationModel[]).map(file => file.specificity) as number[];
                const cohens_kappa = (modifiedModels as ClassificationModel[]).map(file => file.cohens_kappa) as number[];
                const mcc = (modifiedModels as ClassificationModel[]).map(file => file.mcc) as number[];
                const created_by = (modifiedModels as ClassificationModel[]).map(file => file.created_by);
                const access = (modifiedModels as ClassificationModel[]).map(file => file.access);
                
                setFilterFields({
                    
                    n: Array.from(new Set(n)).filter(a => a),
                    auc: Array.from(new Set(auc)).filter(a => a),
                    f1score : Array.from(new Set(f1score)).filter(a => a),
                    precision: Array.from(new Set(precision)).filter(a => a),
                    recall: Array.from(new Set(recall)).filter(a => a),
                    acc: Array.from(new Set(acc)).filter(a => a),
                    specificity: Array.from(new Set(specificity)).filter(a => a),
                    cohens_kappa: Array.from(new Set(cohens_kappa)).filter(a => a),
                    mcc: Array.from(new Set(mcc)).filter(a => a),
                    created_by: Array.from(new Set(created_by)).filter(a => a).map(p => p),
                    access: Array.from(new Set(access)).filter(a => a),
                })

            } else {

                const n = (modifiedModels as RegreesionModel[]).map(file => file.n) as number[];
                const mae = (modifiedModels as RegreesionModel[]).map(file => file.mae) as number[];
                const rmse = (modifiedModels as RegreesionModel[]).map(file => file.rmse) as number[];
                const r2 = (modifiedModels as RegreesionModel[]).map(file => file.r2) as number[];
                const created_by = (modifiedModels as RegreesionModel[]).map(file => file.created_by);
                const access = (modifiedModels as RegreesionModel[]).map(file => file.access);
                
                setFilterFields({
                    n: Array.from(new Set(n)).filter(a => a),
                    mae: Array.from(new Set(mae)).filter(a => a),
                    rmse: Array.from(new Set(rmse)).filter(a => a),
                    r2: Array.from(new Set(r2)).filter(a => a),
                    created_by: Array.from(new Set(created_by)).filter(a => a).map(p => p),
                    access: Array.from(new Set(access)).filter(a => a),
                })
            } 
        }

        
    }, [modifiedModels, isClassification]);


    


    const downloadModels = async (names: string[], name?: string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.post(`models/download`, { names: names, ids: []} ,{ headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                let filename = '';
                if (name !== undefined) {
                    filename = `${name}.xlsx`;
                } else {
                    filename = getDatedFilename('models', 'xlsx');
                } 
                download(filename, data);
                setPopupMessage && setPopupMessage(`The ${names.length > 1 ? 'models you selected were' : 'model you selected was'} successfully exported!`, true);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error downloading ${names.length > 1 ? 'these models' : 'this model'}`, false);
            }
        }
    };

    const updateModelPermissions = async (names: string[], owner: string, access: string) => {
        const authKey = localStorage.getItem('X-Auth');

        const promises = names.map(async (name) => {
            const encoded = encodeURI(name);
            const aclObj = {owner, access, read: [], write: []};
            return API.put(`models/${encoded}/acl`, aclObj, { headers: { 'X-Auth': authKey } }).then(() => API.get(`models/${encoded}`, { headers: { 'X-Auth': authKey } })).then((res) => dispatch(updateModelsByType({ isClassification, model: res.data })));
        });

        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage(`The ${names.length > 1 ? 'models you selected were' : 'model you selected was'} updated successfully!`, true);
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error updating ${names.length > 1 ? 'these models' : 'this model'}`, false);
            }
        }
    };


    const deleteModels = async (names: string[], modelName?: string) => {
        const authKey = localStorage.getItem('X-Auth');

        const promises = names.map(async (name) => {
            const encoded = encodeURI(name);
            if (!modelName) {
                return API.delete(`models/${encoded}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteModelsByType({ isClassification, name })));      
            } else {
                return API.delete(`models/${modelName}/${name}`, { headers: { 'X-Auth': authKey } })
            }
        })
        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage(`The ${names.length > 1 ? 'models you selected were' : 'model you selected was'} deleted successfully!`, true);
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error deleting ${modelName ? names.length > 1 ? 'these methods' : 'this method' : names.length > 1 ? 'these models' : 'this model'}`, false);
            }
        }
    };


    return {
        isLoading,

        allModelNames,
        modifiedModels,
        modifyModels,
        filterFields,
        parsedModels,
        filter,
        setFilter,
        headers,
        setHeaders,

        downloadModels,
        deleteModels,
        updateModelPermissions,
    }

};

export default useModels;