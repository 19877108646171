import { useRef, useEffect, useState } from "react";

const useTruncateText = () => {
    const textRef = useRef<HTMLParagraphElement>(null);
    const [ isTextTruncated, setIsTextTruncated ] = useState(false);

    // Function to check if the text is truncated
  const checkTruncation = () => {
    const element = textRef.current;
    if (element) {
      setIsTextTruncated(element.scrollWidth > element.clientWidth);
    }
  };

  useEffect(() => {
    // Initial truncation check
    checkTruncation();

    // Set up a MutationObserver to watch for changes in the text content
    const observer = new MutationObserver(checkTruncation);
    const currentTextElement = textRef.current;
    
    if (currentTextElement) {
      observer.observe(currentTextElement, {
        childList: true, // Detect changes in child elements (text content)
        subtree: true,   // Observe all descendants of the text element
        characterData: true, // Detect changes in the character data
      });
    }

    // Cleanup the observer when the component is unmounted or dependencies change
    return () => {
      observer.disconnect();
    };
  }, [textRef]);


  return {
    isTextTruncated,
    textRef
  }

};

export default useTruncateText;