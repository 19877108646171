// assets
import Upload from '../../../../assets/upload.svg';

// components
import FlowCard from "../../../../components/Data/FlowCard";
// import FlowCardInfo from "../../../../components/Data/FlowCardInfo";
import Field from '../../../../components/Form/Field';
import Select from 'react-select';

// hooks
import { Dispatch, SetStateAction } from "react";

// types
import { CompleteModel } from "../../../../types/models.types";
import { MouseEvent } from "react";
// import { SideInfo } from "../../../../types/all.types";
import MultipleModelSelections from './MultipleModelSelections';
import { MakePredictionForm } from '../../../../types/predictions.types';
import { PopupType } from '../../../../hooks/usePopup';

const averagingOptions = [
    {value: 'name', label: 'Model Family'},
    {value: 'individual', label: 'Individual Model-Methods'}
]

const getOption = (value: string) => {
    const opt = averagingOptions.find(op => op.value === value);
    if (opt) return opt;
}


export default function UploadModelCard({ number, resetMethods, toggleSelectModel, setPopupType ,selectedModels, deleteModel, isKetcher, form, setForm, isSet, handleMethodSelection, individualMethods}: { 
    // selectedModel: SingleClassificationModel[] | SingleRegressionModel[] | null,
    number: number,
    resetMethods: () => void,
    toggleSelectModel: (e:MouseEvent<HTMLButtonElement>) => void,
    selectedModels?: CompleteModel[] | null,
    deleteModel? : (e: MouseEvent<HTMLButtonElement>, index: number, modelName: string) => void,
    isKetcher?: boolean,
    form?: MakePredictionForm,
    setForm?: Dispatch<SetStateAction<MakePredictionForm>>,
    isSet: boolean,
    handleMethodSelection: (e:any, modelName: string) => void,
    individualMethods: {model: string, methods: string[]}[],
    setPopupType: Dispatch<SetStateAction<PopupType>>,
 }) {

    const handleSelect = (e:any) => {
        if (form && setForm) {
            setForm({...form, average_mode: e.value})
            resetMethods();
        }
    }


    const getMethodValue = (selectedModel: CompleteModel) => {
        const methodObj = individualMethods.find(m => m.model === selectedModel.name);
        if (methodObj) {
            const ids = methodObj.methods;
            let values: {value: string, label: string}[] = [];
            if (selectedModel) {
                ids.forEach(id => {
                    const match = Object.keys(selectedModel.methodIds).find(methodName => selectedModel.methodIds[methodName] === id);
                    // const method = selectedModel.method_ids.find(model => model._id.$oid === id);
                    if (match) {
                        const methodName = match.split('/')[1].trim();
                        values.push({value: selectedModel.methodIds[match], label: methodName})
                    }
                })
            }
            return values;
        } else {
            return [];
        }
        
    };

    const getMethodOptions = (model: CompleteModel) => {
        let options: {value: string, label: string}[] = [];
        console.log(model)
        Object.keys(model.methodIds).forEach(method => {
            const methodName = method.split('/')[1].trim();
            const methodId = model.methodIds[method];

            options.push({ label: methodName, value: methodId });
        })

        return options;
    }


    return (
        <FlowCard label="Select Models" number={number} isLocked={false} isKetcher={isKetcher}>
            <div className="flex flex-col gap-4">
            {isSet && (
                <>
                <Field label='Averaging Mode' isRequired={true}>
                    <Select maxMenuHeight={100} menuPortalTarget={document.body} onChange={handleSelect} options={averagingOptions} value={getOption(form ? form.average_mode : '')}  className='text-left text-[14px] rounded w-full' placeholder='Averaging Mode'/>
                </Field>

                </>
            )}

            {selectedModels && selectedModels.map((model, i) => (
                <>
                    {isSet && form && form.average_mode === 'individual' && (
                        <Field label='Methods' isRequired={true}>
                            <Select isMulti onChange={(e:any) => {handleMethodSelection(e, model.name)}} maxMenuHeight={100} menuPortalTarget={document.body} options={getMethodOptions(model)} value={form ? getMethodValue(model) : []} className='text-left text-[14px] rounded w-full' placeholder='Methods'/>
                        </Field>
                    )}
                    <MultipleModelSelections singleModel={model} index={i} deleteModel={deleteModel}/>
                </>
            ))}

            <div className='flex justify-center items-center p-6 border-2 border-quaternary/[0.5] border-dashed bg-white rounded'>
                <button onClick={(e:any) => {toggleSelectModel(e); setPopupType('existing-model')}} className='transition-all lighten rounded border-2 border-secondary text-secondary text-[14px] font-medium flex justify-center items-center gap-2 bg-white hover:bg-secondary hover:text-white px-4 py-2'>
                    <img src={Upload} className='w-[18px]' alt='upload-icon' />
                    <p>Select model to import</p>
                </button>
            </div>
                
            </div>
        </FlowCard>
    )
}