import SinglePageHeader from "../../../components/Page/SinglePageHeader";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePopup from "../../../hooks/usePopup";
import usePagination from "../../../hooks/usePagination";
import useLayout from "../../../hooks/useLayout";
import useEnableSelection from "../../../hooks/useEnableSelection";
import ProtocolIcon from '../../../assets/alert-square.svg';

import Button from "../../../components/Buttons/Button";
import Info from '../../../assets/info-circle.svg';
import DeletePopup from "../../../components/Popups/DeletePopup";
import ChemicalPopup from "../../../components/Popups/ChemicalPopup";
import Grid from "../../../components/Grid/Grid";

import TableControls from "../../../components/Table/TableControls";
import Pagination from "../../../components/Page/Pagination";
import ToggleView from "../../../components/Page/ToggleView";
import AdditionalInfoSideMenu from "../../../components/Popups/AdditionalInfoSideMenu";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import SingleCompoundOptions from "../../../components/Dropdowns/SingleCompoundOptions";
import ReadacrossPopup from "../../ReadAcross/components/ReadacrossPopup";
import { SideInfo } from "../../../types/all.types";
import SnackBar from "../../../components/Alerts/SnackBar";
import EmptyTable from "../../../components/Table/EmptyTable";
import useSingleDataset from "../../../hooks/pageHooks/useSingleDataset";
import useMessage from "../../../hooks/useMessage";
import { useSelector } from "react-redux";
import { selectApplication, selectTheme } from "../../../store/selectors/auth.selector";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import GridItemBase from "../../../components/Grid/GridItemBase";
import ImageCell from "../../../components/Data/ImageCell";
import OptionsDropdownBase from "../../../components/Dropdowns/OptionsDropdownBase";
import IndividualDatasetOptions from "../components/IndividualDatasetOptions";
import Overlay from "../../../components/Popups/Overlay";
import useModifyTableItems from "../../../hooks/useModifyTableItems";
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";



export default function SingleDataset() {
    const { id } = useParams();
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const [ isLoading ,setIsLoading ] = useState(false);
    const navigate = useNavigate();
    const application = useSelector(selectApplication);
    const theme = useSelector(selectTheme);

    const {
        open,
        setOpen,
        message,
        isSuccess,
        setPopupMessage
    } = useMessage();

    const {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        info,
        routes,
        singleDataset,
        setHeaders,
        headers,
        valueField,
        deleteDatasets,
        downloadDataset,
        updateDataset,
        options,
        stats,
        itemIdentifier,
    } = useSingleDataset({id, setPopupMessage});

    const {
        selectHeaders,
        setSortedColumn,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyRecords, setHeaders, searchInput, selectedHeader, setSelectedHeader })


    const { 
        toggleSideMenu, 
        isSideMenuOpen, 
        isPopupOpen,
        setPopupType,
        popupType,
        togglePopup
    } = usePopup();

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedRecords, count: 50});

    const { 
        toggleView, 
        isGrid 
    } = useLayout();

    const { 
        isChecked,
        addSingleItem,
        deselectItems,
        selectedRows,
        toggleChecked
    } = useEnableSelection({allItems: undefined});

    useEffect(() => {
        if (!singleDataset) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [singleDataset]);

   

    const [ moleculeID, setMoleculeID ] = useState('');
    const [ popupInfo, setPopupInfo ] = useState<SideInfo[]>([])

    useEffect(() => {
        if (moleculeID) {
            const record = modifiedRecords.find(r => r.id === moleculeID);
            if (record) {
                let info: SideInfo[] = [];
                Object.keys(record).forEach(key => {
                    if (key !== 'id' && key !== 'Structure') {
                        info.push({
                            label: key,
                            value: !record[key] ? 'No value' : record[key]
                        })
                    }
                })

                setPopupInfo(info)
            }

            
        }
    }, [moleculeID, modifiedRecords]);

    return (
        <>
            

                <SinglePageHeader label={singleDataset ? singleDataset.name : 'Single Dataset'} isLoading={isLoading} routes={application && (application).includes('assay-central') ? routes : []} stats={stats}>
                    <OptionsDropdownBase isLoading={isLoading}>
                    <IndividualDatasetOptions handleClose={() => {}} application={application} downloadFunc={downloadDataset} toggleDeletePopup={togglePopup} setPopupType={setPopupType} id={id}/>
                    </OptionsDropdownBase>
                    <Button isLoading={isLoading} togglePopup={toggleSideMenu}>
                        <img src={Info} alt='info-logo' className="w-[16px]"/>
                        <p className='font-medium text-nowrap'>Show Details</p>
                    </Button>
                </SinglePageHeader>

                <div className={`w-full rounded ${theme === 'dark' ? 'bg-dark-accent' : 'bg-white'} drop-shadow-default`}>
                {/* Search Bar & Buttons Component */}
                <TableControls applySearch={applySearch} identifier={itemIdentifier ? itemIdentifier : undefined} toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={itemIdentifier ? searchInput : undefined}>
                    <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                    {singleDataset && singleDataset.protocol && (
                        <button onClick={(e:any) => navigate(`/protocols/${singleDataset.protocol.$oid}?dsID=${id}`)} className={` hover:bg-opacity-100 bg-grad-one bg-opacity-30 transition-all text-primary flex justify-center items-center gap-3 py-2 px-5 rounded text-nowrap`}>
                            <img src={ProtocolIcon} className="w-[20px]" alt='go-to-protocol'/>
                            <p className='font-medium hidden lg:block'>Protocol</p>
                        </button>
                    )}
                    <ToggleView toggleView={toggleView} isGrid={isGrid} />
                </TableControls>
                
                {/* Table Component */}
                <div className=''>
                {isGrid ? (
                    <>
                    <Grid isLoading={isLoading} noResults={!paginatedItems.length ? true : false}>
                        {paginatedItems.length > 0 ? (
                            <>
                            {paginatedItems.map(item => {
                                let title = '';

                                const h = headers.find(header => header.isRequired && header.label !== 'Structure');
                                if (itemIdentifier) {
                                    title = item[itemIdentifier];
                                } else if (h) {
                                    title = item[h.value];
                                }
                                return (
                                    <GridItemBase
                                        title={title}
                                        optionButton={
                                            <IndividualOptionsDropdownBase isGrid={true}>
                                                <SingleCompoundOptions handleClose={() => {}} addSingleItem={addSingleItem} togglePopup={togglePopup} setPopupType={setPopupType} moleculeID={item.id}/>
                                            </IndividualOptionsDropdownBase>
                                        }
                                        isChecked={false}
                                        gridType="structure"
                                        activeField={valueField}
                                        item={item}
                                    >
                                        <button onClick={(e:any) => {togglePopup(e); setPopupType('chemical') ;setMoleculeID(item.id)}}>
                                            <ImageCell type="grid" id={item.id} />
                                        </button>
                                        <div className='max-h-[180px] overflow-y-scroll'>
                                            <div className='flex flex-col gap-4 justify-center items-stretch'>
                                            {Object.keys(item).filter(key => key !== 'Structure' && key.toLocaleLowerCase() !== 'smiles').map(key => (
                                                <div className='flex flex-col justify-start items-start gap-[2px]'>
                                                    <p className="text-[12px] text-secondary font-semibold">{key}:</p>
                                                    <p className={`text-[14px] ${key.includes('smile') ? 'break-all' : 'break-words'} ${!item[key] ? 'text-[#a9a9a9]' : 'text-primary'} font-medium text-left`}>{!item[key] ? 'No value' : item[key]}</p>
                                                </div>
                                            ))}
                                            </div>
                                        </div>
                                    </GridItemBase>
                                )})}
                            </>
                        ) : (
                            <EmptyTable isGrid={true} subject="This dataset contains no records" searchInput={searchInput} />
                        )}
                        
                    </Grid>
                    {paginatedItems.length > 0 && (
                        <Pagination count={50} total={orderedRecords.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                        firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                    )}
                    </>
                ) : (
                    <>
                    {isLoading || paginatedItems.length > 0 ? (
                        <>
                            <TableBase
                                activeField={valueField} 
                                hasOptions={isLoading ? false : true} 
                                isLoading={isLoading} 
                                headers={headers} 
                                selectedHeader={selectedHeader} 
                                setSortedColumn={setSortedColumn}
                                isPopupOpen={false}
                                isChecked={false}
                            >
                                {paginatedItems.length > 0 && paginatedItems.map(item => (
                                    <TableRowBase
                                        isChecked={false} 
                                        item={item} 
                                        headers={headers} 
                                        action={{togglePopup, setPopupType, setMoleculeID}}
                                        activeField={valueField}
                                        rowType="base"
                                    >
                                        <IndividualOptionsDropdownBase>
                                            <SingleCompoundOptions handleClose={() => {}} addSingleItem={addSingleItem} togglePopup={togglePopup} setPopupType={setPopupType} moleculeID={item.id}/>
                                        </IndividualOptionsDropdownBase>
                                    </TableRowBase>
                                ))}
                            </TableBase>
                            {paginatedItems.length > 0 && (
                                <Pagination count={50} total={orderedRecords.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                                firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                            )}
                        </>
                    ) : (
                        <EmptyTable subject="This dataset contains no records" searchInput={searchInput} />
                    )}
    
                    </>
                    )}
                </div>

                
                </div>

                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>
          
            {isSideMenuOpen && (
                <AdditionalInfoSideMenu id={id} options={options} updateFunc={updateDataset} info={info} label='Dataset Details' togglePopup={toggleSideMenu} />
            )}
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup toggleChecked={toggleChecked} isChecked={isChecked} deleteFunc={deleteDatasets} items={id ? [id] : []} togglePopup={togglePopup} label='this dataset' path='/datasets'/>
                    ) : popupType === 'chemical' ? (
                        <ChemicalPopup togglePopup={togglePopup} id={moleculeID} info={popupInfo}/>
                    ) : (
                        <ReadacrossPopup togglePopup={togglePopup} deselectItems={deselectItems} items={selectedRows} />
                    )}
                </Overlay>
            )}
           

        </>
    )
}